$(function() {
    "use strict";

    // LIST PRODUCTS SWIPER INIT
    
    //get viewport width
    const viewportWidth = $(window).width();
    // set slides viewing and space between slides
    let slidesViewing, spaceBetweenSlides = 0;

    //set slides viewing depending on viewport width
    if (viewportWidth <= 4000 && viewportWidth >= 1800) {
        slidesViewing = 9;
        spaceBetweenSlides = 8;
    } else if (viewportWidth < 1800 && viewportWidth >= 1500) {
        slidesViewing = 8;
        spaceBetweenSlides = 10;
    } else if (viewportWidth < 1500 && viewportWidth >= 1200) {
        slidesViewing = 6;
        spaceBetweenSlides = 12;
    } else {
        slidesViewing = 4;
        spaceBetweenSlides = 15;
    }
    
    $('body').on('mouseenter touchstart', '.productSwiper', function () {

        // Set different color swiper params depending on the parent
        var color_swiper_params;
        if ($(this).parents('.product_associations_wrapper').length) {
            color_swiper_params = {
                slidesPerView: 8,
                spaceBetween: 8,
                loop: false,
                roundLengths: true,
                navigation: {
                    nextEl: $(this).parents('.item').find('.productColorFieldset .swiper-button-next')[0],
                    prevEl: $(this).parents('.item').find('.productColorFieldset .swiper-button-prev')[0],
                },
            }
        } else {
            color_swiper_params = {
                loop: false,
                roundLengths: true,
                navigation: {
                    nextEl: $(this).parents('.item').find('.productColorFieldset .swiper-button-next')[0],
                    prevEl: $(this).parents('.item').find('.productColorFieldset .swiper-button-prev')[0],
                },
                breakpoints: {
                    4000: {
                        slidesPerView: slidesViewing,
                        spaceBetween: spaceBetweenSlides,
                    },
                    1950: {
                        slidesPerView: slidesViewing,
                        spaceBetween: spaceBetweenSlides,
                    },
                    1800: {
                        slidesPerView: slidesViewing,
                        spaceBetween: spaceBetweenSlides,
                    },
                    1700: {
                        slidesPerView: slidesViewing,
                        spaceBetween: spaceBetweenSlides,
                    },
                    1500: {
                        slidesPerView: slidesViewing,
                        spaceBetween: spaceBetweenSlides,
                    },
                    1200: {
                        slidesPerView: slidesViewing,
                        spaceBetween: spaceBetweenSlides,
                    },
                }
            }
        }

        if (!$(this).hasClass('swiper-container-initialized') && ($(this).find('.swiper-slide').length > 1)) {
            var productSwiper = new Swiper($(this), {
                slidesPerView: 1,
                loop: false,
                navigation: {
                    nextEl: '.rollover_rayonsNext',
                    prevEl: '.rollover_rayonsPrev',
                },
                preloadImages: false,
                lazy: true,
            });
        }

        // Dynamic color swiper init
        if ($(this).parents('.item').find('.productColorFieldset .swiper-container').length && ($(this).parents('.item').find('.productColorFieldset .swiper-slide').length > slidesViewing)) {
            // Be sure that the swiper is not already init
            if (!$(this).parents('.item').find('.productColorFieldset .swiper-container-initialized').length) {
                new Swiper($(this).parents('.item').find('.productColorFieldset .swiper-container'), color_swiper_params);
            }
        }

    });

    //swiper homepage cover module
    if ($("#coverSwiper .swiper-wrapper .swiper-slide").length > 1) {
        var allowTouchMove = false;

        if ($("#coverSwiper .swiper-wrapper .swiper-slide").length > 1) {
            allowTouchMove = true;
        }

        var coverModuleHomeSwiper = new Swiper("#coverSwiper", {
            loop: true,
            autoplay: {
                delay: 5000,
            },
            speed: 2000,
            slidesPerView: 1,
            spaceBetween: 40,
            allowTouchMove: allowTouchMove,
            navigation: {
                nextEl: ".cover-module .cover-next",
                prevEl: ".cover-module .cover-prev",
            },
        });
    }

    // Init module multiple bloc swipers
    if ($('.home-product-list-wrapper').length) {
        initMultilpleBlocSwiper();
    }

    const observer = new MutationObserver(function(mutations_list) {
        mutations_list.forEach(function(mutation) {
            mutation.addedNodes.forEach(function(added_node) {
                if (typeof added_node.className != "undefined"  && added_node.className == "home_modules_search") {
                    initMultilpleBlocSwiper();
                }
            });
        });
    });

    observer.observe(document.querySelector("#site_global_wrap"), { subtree: true, childList: true });

    //swiper storelocator gestion du parc - img de la boutique
    var storeSliderWrapper = new Swiper(".store_slider_wrapper", {
        slidesPerView: 'auto',
        spaceBetween: 24,
        roundLengths: true,
    });

     // positionnement éclats fiche produits
    if ($('.product_page .eclat_wrapper .eclat_rect').length) {
        $('.eclat_wrapper .eclat_rond').css('right', '132px');
    }




    var siteHeader = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");

    function onWinScroll() {
        if (!document.body.classList.contains("in_tunnel")) {
            var scrollTop = $(document).scrollTop();

            if (siteHeader.classList.contains("sticky")) {
                if (scrollTop === 0) {
                    siteHeader.classList.remove("sticky");
                    shade.classList.remove("sticky");
                    document.body.classList.remove('scroll_on');
                    $('#site_head_wrap .bandeau_header').slideDown(400);
                }
            } else {
                if (scrollTop !== 0) {
                    siteHeader.classList.add("sticky");
                    shade.classList.add("sticky");
                    document.body.classList.add('scroll_on');
                    $('#site_head_wrap .bandeau_header').slideUp(400);
                }
            }
        }
    }

    function onSearchStateChange(evt) {
        if (evt.type === "focus" && (evt.target.value.length >= 3)) {
            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {
            search.parentElement.classList.remove("searching");
        }
    }

    $(window).on("scroll", onWinScroll);
    $(search).on({focus: onSearchStateChange, blur: onSearchStateChange});

    onWinScroll();

    /* Pre header swiper */
    if ($('.bandeau_header .swiper-container').length && ($('.bandeau_header .swiper-container .swiper-slide').length > 1)) {
        var preheader_swiper = new Swiper('.bandeau_header .swiper-container', {
            autoplay: true,
            slidesPerView : 1,
            loop: true,
            speed: 2000,
        })
    }

    $(document).on("click", ".button", btnLoaderTrigger);

    //MOVE ARIANE FUNCTION
    if(($('.wrapper_content_sat.page_text').length)) {
        $('.main_ariane').insertAfter($('.wrapper_content_sat.page_text .title'));
        $('.main_ariane').css('display', 'block');
    }
});

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

// // Buttons loader management
function btnLoaderTrigger(evt) {
    "use strict";
    var loader = $(this).next(".loader");

    if (loader.length > 0) {
        // Disable prevent.default on form in order to allow the submit
        if (!$(this).parents('form').length) {
            evt.preventDefault();
        }

        loader.removeAttr("style").parent().addClass("loading");
        loader.removeClass("cache");
        loader.addClass("loading");

        setTimeout(function () {
            loader.parent().removeClass("loading");
            loader.addClass("cache");
            loader.removeClass("loading");
        }, 1200);
        
        var href = $(this).attr("href");
        if (href && href != '#' && href != 'javascript:void(0)') {
            window.location.assign($(this).attr("href"));
        }
    }
}

function openMultiShad(id) {
    if ($('.lightbox.actif').length) {
        $('.lightbox.actif').removeClass('actif');
    }

    $("#" + id).addClass('actif');

    if (!$("#shad").hasClass('actif')) {
        $("#shad").addClass('actif');
    }
    $("#shad").on("click", function () {
        closeMultiShad(id);
    });

    if (id == "lightbox_achat_express") {
        var prod_id,
        form,
        prod_qty,
        prod_rr_id,
        prod_is_wish;

        prod_id = $('#lightbox_achat_express #produit_id').val();
    }

    if (id === "zoomBox") {
        $("#shad").addClass('white');
        $('#zoom_box_wrapper .img_large').on('click', function () {
            closeMultiShad('zoomBox');
        });

        if ($("#zoom_box_wrapper .swiper-slide").length > 0) {
            var main_productVisu = new Swiper('#zoom_box_wrapper', {
                slidesPerView: 1,
                navigation: {
                    nextEl: '.zoomNext',
                    prevEl: '.zoomPrev',
                },
                preloadImages: false,
                lazy: true
            });
        }
    }

    if (id === 'popup_savoir_plus') {
        $("#popup_savoir_plus").css("display", "block");
        $('#shad').css('display', 'block');
    }

    if (id === 'lightboxOrderGiftCard' || id === 'sendwishlistbox') {
        $('#shad').addClass('actif');
    }

    if (id === 'selection-offer') {
        $('#' + id).find('#selection-offer-content').overlayScrollbars({});

        $('.js-form-selection-offer').on('mouseenter touchstart', function() {
            if ((!$(this).find('.color_list.swiper-container-initialized').length) && ($(this).find('.color_list .swiper-slide').length > 8)) {
                var color_swiper = new Swiper($(this).find('.color_list'), {
                    roundLengths: true,
                    navigation: {
                        prevEl: $(this).find('.color_list .prevColour')[0],
                        nextEl: $(this).find('.color_list .nextColour')[0],
                    },
                    breakpoints: {
                        1950: {
                            slidesPerView: 6,
                            spaceBetween: 10,
                        },
                        1100: {
                            slidesPerView: 4,
                            spaceBetween: 16,
                        }
                    }
                });
            }
        });
    }
}

function closeMultiShad(id) {
    $('body').css('position','');
    $("#" + id).removeClass('actif');
    $("#shad").removeClass('actif');
    $(".shad").removeClass('actif').removeClass('white');

    if (id === "exit_overlay_OVI") {
        $("#shad").removeClass('actif');
        $("#exit_overlay_OVI").hide();
    }

    if (id === "zoomBox") {
        $("#shad").removeClass('white');
    }
}

function changeLocale(locale, uri) {
    $.ajax({
        url: path_relative_root + "ajax_get_uri_by_lang.php",
        type: "post",
        data: {lang: locale, url: uri},
        success: function(data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        },
    });
}

var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                //window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

// Reload product from ref
function loadProduct(new_id, type, page, itm_rr_id) {

    var form = this;
    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;
    if (this.elements.namedItem('qteProd')  ) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v1 = $('.express_response').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var isCategSuper = this.getValue('isCategSuper') ? this.getValue('isCategSuper') : 0;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id : old_id,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express : (is_wishlist || is_achat_express_v1) ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1,
        isCategSuper : isCategSuper
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type : 'post',
        url : path_relative_root + 'ajax_get_product_regroup_ref.php',
        data : data,
        success : function (res) {

            try {

                res = $.parseJSON(res);
            } catch (e) {

                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {

                var datas = res.datas;

                if (data.type_page == 'product') {

                    var $html_main = $(stripCombo(datas.html_main));

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);
                    var fp_associations = $('.product_associations_wrapper', $html_main);

                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    } else if (is_wishlist || is_achat_express_v1) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());

                        if ($("#buyNowSwiper").length > 0) {
                            var buyNowSwiper = document.getElementById("buyNowSwiper");

                            if (buyNowSwiper.getElementsByClassName("swiper-slide").length) {

                                buyNowSwiper = new Swiper("#buyNowSwiper", {
                                    sliderPerView: 1,
                                    nextButton: '.swiper-button-next',
                                    prevButton: '.swiper-button-prev'
                                });
                            }
                        }

                        if (!$(".w-alertestock-form").length) {
                            new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
                        }

                    } else {


                        var $html_middle = $(stripCombo(datas.html_middle));

                        var color = $("#ligne_couleur").html();

                        var taille = $("#ligne_pointure").html();

                        var contents = $('<div />').html($html_main);

                        // Photo et information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());

                        // Zoombox
                        $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());

                        // Associations
                        $('.product_associations_wrapper').html(fp_associations.html());

                        var video = $('.wrapper_video', contents);

                        $("#ligne_couleur").html(color);
                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);

                        // On scroll en haut de la page dans le cas d'un regroupement
                        $('html, body').animate({
                            scrollTop: 0
                        }, 500);
                    }
                } else if (data.type_page == 'assoc') {
                    if (data.is_achat_express_v2) {

                        // Photo et information
                        var $html_main = $(stripCombo(datas.html_main));
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    }
                }

                json_colisages = res.stock;
                // On definie les tailles qui ont encore du stock
                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                // Alerte stock logic
                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

            } else {
                $( '#btn_error' ).fadeIn();
            }
        },
        complete : function () {

            var heads      = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function(event) {
                event.preventDefault();

                var element        = $(this);
                var tab_number     = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container      = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            // Resetting alert stock field for other pages except category and search_on (rayon/recherche)
            if ( !$( "body" ).hasClass( "category" ) && !$( "body" ).hasClass( "search_in" ) ) {
                setTimeout( function () {
                    $(".bloc_add_alert_form").hide();
                }, 250 );
            }

            setTimeout(function () {
                if ($("#jqzoom .swiper-slide").length > 0) {
                    var main_productVisu = new Swiper('#jqzoom', {
                        slidesPerView: 1,
                        navigation: {
                            nextEl: '.mainVisNext',
                            prevEl: '.mainVisPrev',
                        },
                        pagination: {
                            el: ".swiper-pagination",
                        },
                        lazy: true,
                        loadPrevNext: true,
                        loadOnTransitionStart: true
                    });

                    var stillImageProduct = new Swiper('.stillImageProduct', {
                        slidesPerView: 1,
                        noSwiping: true,
                        lazy: true,
                        loadPrevNext: true,
                    });

                    main_productVisu.controller.control = stillImageProduct;
                }

                setTimeout(function () {
                    checkSubmitState();
                    eventButton();
                }, 500);
            }, 250);

            heads.each(function() {
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist);

            checkProductInWishlist(data.produit_id_new, itm_rr_id);

            if (is_wishlist) {
                // event sur le bouton d'ajout à la wishlist
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            // Reinit FP swiper color loadProduct
            if($('.page_produit').length){
                if ($('#colorsSwiperFp.swiper-container').length) {
                    new Swiper('#colorsSwiperFp.swiper-container', {
                        roundLengths: true,
                        navigation: {
                            nextEl: '.fp_swiper_button.swiper-button-next',
                            prevEl: '.fp_swiper_button.swiper-button-prev',
                        },
                        breakpoints: {
                            4000: {
                                slidesPerView: 13,
                                spaceBetween: 8,
                            },
                            1950: {
                                slidesPerView: 13,
                                spaceBetween: 8,
                            },
                            1800: {
                                slidesPerView: 10,
                                spaceBetween: 10,
                            },
                            1700: {
                                slidesPerView: 8,
                                spaceBetween: 12,
                            },
                            1500: {
                                slidesPerView: 6,
                                spaceBetween: 14,
                            },
                            1200: {
                                slidesPerView: 5,
                                spaceBetween: 15,
                            },
                        }
                    })
                }

                // Associations reload
                if ($("#gondole_complete .swiper-slide").length > 0) {
                    gondole_complete = new Swiper("#gondole_complete", {
                        loop: false,
                        slidesPerView: 4,
                        spaceBetween: 24,
                        navigation: {
                            nextEl: ".gondole_complete_btn.swiper-button-next",
                            prevEl: ".gondole_complete_btn.swiper-button-prev",
                        },
                        breakpoints: {
                            1030: {
                                slidesPerView: 2,
                                spaceBetween: 40
                            },
                            1320: {
                                slidesPerView: 3,
                                spaceBetween: 40
                            }
                        }
                    });
                }

                // Thumb click reload
                displayFpThumb();
            }

            //Simulates scroll (2px) to load images
            window.scrollTo(0, $(window).scrollTop()+2);
        }
    });
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id) {

    var control, prod_id, from_page;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);
    var indexSlide = $(this).parents('.swiper-slide').attr('data-pi');


    if (this.checked) {

        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        var is_assoc_product = form.getValue('isAssocProduct') ? form.getValue('isAssocProduct') : 0;

        if (is_assoc_product !== '1') {
            from_page = 'product';
        } else {
            from_page = 'assoc';
        }

        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur', from_page, itm_rr_id);
        } else {
            // On regarde si le produit est dejà dans la wishlist
            checkProductInWishlist(prod_id, itm_rr_id);

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }

    setTimeout(function() {
        if ($("#productVisitedSwiper .swiper-slide").length > 0) {
            productVisitedSwiper = new Swiper("#productVisitedSwiper", {
                loop: false,
                slidesPerView: 4,
                spaceBetween: 40,
                pagination: {
                    el: ".swiper-pagination",
                    type: "progressbar",
                },
                navigation: {
                    nextEl: ".swiper-button.swiper-button-next",
                    prevEl: ".swiper-button.swiper-button-prev",
                },
                breakpoints: {
                    1030: {
                        slidesPerView: 2,
                        spaceBetween: 40
                    },
                    1320: {
                        slidesPerView: 3,
                        spaceBetween: 40
                    }
                }
            });
        }
    }, 600);
}

/**
 * @param product_id : l'id du produit
 * @param regroup_ref_id : l'id du regroupement (si exist)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id: '_' + product_id;

    //Dans le cas ou une recherche est lancée depuis une page produit
    //On s'assure de cibler les informations de l'achat express du produit de la recherche
    //Afin d'éviter un bug sur l'achat express, dans le cas ou le produit de la recherche est le même que celui de la page produit
    //[0] est utilisé pour utiliser l'élement du DOM et non l'objet jQuery
    if ($('.dynasearchwrapper').length)  {
        var form = $('.dynasearchwrapper #prod_info' + elem)[0];
    } else {
        var form = $('#prod_info' + elem)[0];
    }

    var blocWrapperColorSize = $(".rollover_left",$(form));
    var color_id = form.elements['itm_color'].value;
    var size_id = form.elements['itm_size'].value;
    var txt_error = '';

    $("#ligne_pointure_error"+elem).remove();

    // Vérification si la taille sélectionnée n'est pas désactivée ce qui permet d'afficher l'alerte stock
    if ($('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) {
        $(this).parent().hide();
        blocWrapperColorSize.hide();
        $('#bloc_add_alert'+elem).show();
    } else {
        // On ajoute bien le produit en faisant les vérifications sur la taille et la couleur
        // On regarde si la personne a sélectionné la taille et la couleur
        if (color_id !== '' && size_id !== '') {
            waitingAddToBasket( event, form, '', regroup_ref_id );

            if ($(window).height() > 899 && $("#cart_top #cart_list .product-link").length > 3) {
                setTimeout(function() {
                    $("#cart_top #cart_list").overlayScrollbars({});
                }, 1001);
            } else if ($("#cart_top #cart_list .product-link").length > 2) {
                setTimeout(function() {
                    $("#cart_top #cart_list").overlayScrollbars({});
                }, 1001);
            }

            setTimeout(function() {
                btn.parent().removeClass('loading');
            }, 2000);

        } else {
            if (color_id === '' && size_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_size_and_a_color')+'</div>';
            } else if (color_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_color')+'</div>';
            } else if (size_id === '') {
                var txt_error = '<div class="ligne_form productErrorFieldset" id="ligne_pointure_error'+elem+'">'+Translator.translate('quick_purchase_choose_a_size')+'</div>';
            }
            // Loader
            setTimeout(function() {
                btn.parent().removeClass('loading');
            }, 600);
            $(txt_error).insertBefore($("#ligne_pointure"+elem));
        }
    }
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {
    var control;
    var isWishList = $('#wishlist').length > 0;
    var isAchatExpressV2 = ($('#is_achat_express_v2').val() == 1 && !isWishList);
    var productId = form.getValue('produit_id');
    var elem = isAchatExpressV2 ? '_' + productId + itm_rr_id : '';
    var productRef = form.getValue('produit_ref');
    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();
    var from  = ($('#is_from_lookbook').val() !== undefined) ? 'lookbook' : 'product';
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    var productSizeFieldset = $(".productSizeFieldset");
    var sizesList = $(".sizesList");
    var shadSelect = $(".shadSelect");

    productSizeFieldset.removeClass("actif");
    sizesList.removeClass("opened");
    shadSelect.removeClass("actif");


    if (this.checked) {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });
            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        $('.choosenSize').html(this.dataset.nom);

        // Depending on the color we see if the product is available, do this only in product page
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), from, event, itm_rr_id, isWishList, productRef);

        // We check if the product is already in the wishlist
        checkProductInWishlist(productId, elem);

    }
}

/**
 * Refreshes available quantity
 * @param {*} id 
 * @param {*} qty 
 * @param {*} from 
 * @param {*} event 
 * @param {*} itm_rr_id 
 * @param {*} is_wishlist 
 * @returns {void}
 */
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var color_id = $('#couleurProd' + elem).val();
    var size_id = $('#tailleProd' + elem).val();
    var plnb = form.getValue('produit_lot_nb');

    // If we don't have a size or color already selected then we don't do any verification
    if (color_id == '' || size_id == '') {
        return;
    }

    $('#sizeerror').addClass('hided');

    var data = {
        idprod: form.getValue('produit_id'),
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from == 'basket') {
        data.suff = form.getValue('produit_id');
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_liste_quantity'),
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            // Check if globale delivery country
            if (typeof GEP != 'undefined' && GEP.isOperatedByGlobale!== undefined) {
                var isOperatedByGlobalE = GEP.isOperatedByGlobale;
            }
            // OR is express checkout AND have no warehouse stock AND product not SFS
            if (res.disable_addtocart == true && ((typeof isOperatedByGlobalE != 'undefined' && isOperatedByGlobalE !== false)
                || (is_achat_express && ((typeof GEP == 'undefined' || (typeof isOperatedByGlobalE !== 'undefined' && isOperatedByGlobalE === false)) && typeof res.is_sfs !== "undefined" && res.is_sfs == false)))) {
                res.stock_tampon = 0;
            }

            if (res.stock_tampon == 0) {
                // For the display of the cta available mag if not in stock
                if ($("#bloc_availability").length) {
                    $("#bloc_availability").show();
                }

                $("#bloc_add_alert" + elem).show();
                $("#prod_info" + elem + " .bloc_add_color").hide();

                if (!is_achat_express && is_wishlist == false) {
                    $("#bloc_add_basket").hide();
                    if (!$(".w-alertestock-form").length) {
                        new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
                    }
                }

                $('#ligne_qte').append($('.restock_date'));
                $('.taunt_text').css('display', 'none');

                if ($('input#color_' + id + '_' + color_id + itm_rr_id).length !== 0) {
                    $('input#color_' + id + '_' + color_id + itm_rr_id).addClass('disabled');
                } else if ($('input#color_' + color_id).length !== 0) {
                    $('input#color_' + color_id).addClass('disabled');
                }

            } else {
                if (res.stock_tampon > 0 && res.stock_tampon <= 5) {
                    if (res.stock_tampon == 1) {
                        $('.taunt_text').html(Translator.translate('last_taunt', res.stock_tampon));
                    } else {
                        $('.taunt_text').html(Translator.translate('last_taunts', res.stock_tampon));
                    }

                    $('.taunt_text').css('display', 'block');
                } else {
                    $('.taunt_text').css('display', 'none');
                }

                if (is_achat_express) {

                    if (from == 'product') {
                        // Show bloc colors when we add product to basket
                        $("#bloc_add_alert" + elem).hide();
                        $("#prod_info" + elem + " .bloc_add_color").show();
                    }

                } else {
                    $("#bloc_add_alert").hide();
                    $("#bloc_add_basket").show();
                    // Otherwise we hide the cta available mag if there is stock
                    if ($("#bloc_availability").length) {
                        $("#bloc_availability").hide();
                    }
                }

                if ($('input#color_' + id + '_' + color_id + itm_rr_id).length !== 0) {
                    $('input#color_' + id + '_' + color_id + itm_rr_id).removeClass('disabled');
                } else if ($('input#color_' + color_id).length !== 0) {
                    $('input#color_' + color_id).removeClass('disabled');
                }
            }
            // Datalayer v2
            if (res.dataLayer_object != undefined) {
                pushIntoDatalayer(res.dataLayer_object);
            }

            // Check if globale delivery country
            if (res.disable_addtocart === true) {
                if ( (typeof GEP  != 'undefined' && GEP.isOperatedByGlobale !== undefined &&  GEP.isOperatedByGlobale !== false) ||
                    ((typeof GEP == 'undefined' || (typeof isOperatedByGlobalE != 'undefined' && isOperatedByGlobalE === false)) && typeof res.is_sfs !== "undefined" && res.is_sfs == false)) {
                    $("#bloc_add_basket").hide();
                    $("#bloc_add_alert").show();
                    $(".product_main_wrapper #size_"+size_id).addClass('disabled');
                }
            }

            $("#bloc_add_cart").prop("disabled", false);
        }
    });
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var produit_refext = form_elm.getValue('produit_refext');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    if (!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass("loading");
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id) {

    var id = '';

    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true || is_achat_express) {
        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {
            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var prix = document.getElementById(prix_id) ? document.getElementById(prix_id).value : '';

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, prix, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {
        if (from == 'basket') {
            location.reload();

            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {
            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {
            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {
            loader.removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {
            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            pushIntoDatalayer(array_panier[5]);
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + create_link('ajax_show_basket'));

        $("#cart_top").html(response_basket);

        var count_bask = $('#cart_box .cart').attr('data-cart');

        if (count_bask > 2) {
            $("#cart_top .cart_product_listing").overlayScrollbars({});
        }

        newshowmodal(produit_id, is_not_produit);
    } else {
        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }
    return false;
}

function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = $("#couleurProd" + i);
    var tailles = $("#tailleProd" + i);
    var res = "";
    var html_err = "";
    var flagError = false;

    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == "") {
        $("#sizeerror").slideDown();

        return false;
    } else if (couleurs && couleurs.val() == "") {
        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }


    if (flagError) {
        alert(res);

        return false;
    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = idTaille = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != 'none') {
            idCouleur = couleurs.val();
        }

        if (tailles && tailles.val() != 'none') {
            idTaille = tailles.val();
        }

        if (idCouleur === undefined) {
            idCouleur = idElCouleur;
        }

        if (idTaille === undefined) {
            idTaille = idElTaille;
        }

        var array_response = [];
        var response = ajax_file(
            path_web +
            "ajax_upd_panier.php?prixu=" +
            prixU +
            "&idprod=" +
            idProduit +
            "&idcouleur=" +
            idCouleur +
            "&idtaille=" +
            idTaille +
            "&qte=" +
            qte +
            "&panierId=" +
            paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                            ].text;
                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation +=
                        " - " +
                        $('[name="' + idElCouleur + '"]:checked')
                            .get(0)
                            .nextElementSibling.title.trim();
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == "false") {
                $("#size_" + idTaille).addClass("disabled");
                alert(Translator.translate("product_no_stock_2"));
                return false;
            }
        }

        return array_response;
    }
}

// FUNCTION PUSH INTO DATALAYER
function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

// FIX BUG LAZYLOAD ADD TO CART FOR WHISLIST IMAGE
function updateTunnelElements() {

    if ($('.pushBasketWrapper').length) {
        var target;
        if ($('body.step_1 .left_side.elem_tunnel .cart_main_table.basket').length) {
            target = "body.step_1 .left_side.elem_tunnel .cart_main_table.basket";
        } else {
            target = "body.step_1 .left_side.elem_tunnel .cart_main_title.basket";
        }
        if (!$(target).find('.pushBasketWrapper').length) {
            $('body.step_1 .pushBasketWrapper').insertAfter(target).show();
        }
    }
    if ($('.pushBasketWrapperText').length) {
        var target;
        if ($('body.step_1 .left_side.elem_tunnel .cart_main_table.basket').length) {
            target = "body.step_1 .left_side.elem_tunnel .cart_main_table.basket";
            if (!$(target).find('.pushBasketWrapperText').length) {
                $('body.step_1 .pushBasketWrapperText').insertBefore(target).show();
            }
        } else {
            target = "body.step_1 .left_side.elem_tunnel .cart_main_title.basket";
            if (!$(target).find('.pushBasketWrapperText').length) {
                $('body.step_1 .pushBasketWrapperText').insertAfter(target).show();
            }
        }
    }
}

// Alters item quantity in cart
function changeQte(type) {
    var types = [ 'plus', 'less' ],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {
        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'block';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url : path_relative_root + create_link("order_basket"),
            type : 'post',
            data : {
                action : 'del',
                panierId : item,
                cad  : 'non',
                pointcad : 0,
                qteProd : qtty,
                change_qte : true
            },
            cache : false,
            success : function (res) {
                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();

                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc+=1;
                }

                // Cart slider initialization
                var sliderCart = $('#main_cart_wrapper #basketAssocWrapper');

                if (sliderCart.length) {
                    sliderCart.remove();
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;

                var $label_basket_line_qte = Translator.translate('quantity');
            },
            complete : function() {

                var total = $('#cart_top .link_item_cart').attr('data-cart'),
                cart = Translator.translate('mon_panier'),
                text;

                if (total <= 1) {
                    text = Translator.translate('one_product');
                } else {
                    text = Translator.translate('2_product');
                };
                $('.cart_main_title.basket').html(cart +' '+ '<span class="nb_products">(' + total + ' ' + text + ')</span>');

                // Update Cart elements after ajax reload
                updateTunnelElements();

            }
        });
    }
}

// Tunnel FAQ
function fadePanier(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#texte_question_" + i).css('display') == 'block') {
            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {
        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {
        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

/** Function newshowmodal
 * Shows cart rollover
 *
 */
function newshowmodal (products, type, root, id_produit, is_not_produit) {
    //toggleRolloverLinkItem();
    topCartAppear();

    window.setTimeout(function () {

        topCartDesappear();
    }, 2000);
}

function topCartAppear() {
    var cart_top = $('#cart_top');
    cart_top.addClass('active_rollover')


    $('#show_top_cart #cart_list .holder_scrollable').overlayScrollbars({
        scrollbars: {
            autoHide: "leave",
            autoHideDelay: 0,
        }
    });

    triggerShad('menu', 'add');
}


/** Function displayWishlistRollover
 * Shows wishlist rollover
 * @params {*} null
 */
function displayWishlistRollover() {
    topWishAppear();

    window.setTimeout(function () {

        topWishDesappear();
    }, 2000);
}

function topWishAppear() {
    var cart_top = $('#wishlist_top');
    cart_top.addClass('active_rollover')

    if ($('#wishlist_list .holder_scrollable').length) {
        $('#wishlist_list .holder_scrollable').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

    triggerShad('menu', 'add');
}

function topWishDesappear() {
    var cart_top = $('#wishlist_top');
    cart_top.removeClass('active_rollover')

    triggerShad('menu', 'remove');
}

function closeAlerteStock(elem) {
    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();

    $('#ligne_pointure_' + elem + ' .size input:checked').prop('checked', false)

    $('.bloc_add_color').show();
}

/*
    Permet l'envoi du mail qui confirme l'enregistrement de sont email,
    pour alerter la personne quand le produit sera disponible
*/
function sendMailAlertStock(type_alert, form, itm_rr_id) {
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();

    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {
        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {
        $('#mail_alerte_stock' + elem).removeClass('inputErr');

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function(response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
                $('.bloc_add_alert' + elem + ' .alert_return').show();
            },
            fail: function() {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 */
function setAvailableSizes() {
    if (json_colisages !== undefined && json_colisages != null && $(this).val() in json_colisages) {
        var sizes_tab = json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                    '[value="' + size_id + '"]'
                );

                is_disabled = !sizes_tab[size_id];

                if (is_disabled) {
                    size_box.addClass('disabled');

                    if ($('input#color_' + $(this).val()).length !== 0) {
                        $('input#color_' + $(this).val()).addClass('disabled');
                    }

                } else {
                    size_box.removeClass('disabled');

                    if ($('input#color_' + $(this).val()).length !== 0) {
                        $('input#color_' + $(this).val()).removeClass('disabled');
                    }

                }
            }
        }
    }
}

function btnLoaderUnloading(btn) {
    "use strict";

    var loader = btn.next(".loader");

    if (loader.length > 0) {
        setTimeout(function() {loader.removeAttr("style").parent().removeClass("loading")}, 200);
    }
}

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");
            return false;
        }

        $(objElement).removeClass("inputErr");
        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file(
                        "ajax_sprintf.php?arg2=" + fieldname + "_mobile"
                    ) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                            fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg2=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}


function initMultilpleBlocSwiper() {
    //swiper homepage multibloc 1 et 2
    if ($(".home-product-list-wrapper").length) {
        var cnt = 0;
        // Init each swiper (you can have more than one module multiple-bloc on the homepage)
        $(".home-product-list-wrapper").each(function(e){
            cnt++;
            $(this).addClass('product-list-'+ cnt);
            var nextBtn = $(this).next('.swiper-button-next').addClass('btn-next-'+ cnt);
            var prevBtn = $(this).next('.swiper-button-next').next('.swiper-button-prev').addClass('btn-prev-'+ cnt);
            var articleCms = new Swiper('.product-list-'+ cnt, {
                spaceBetween: 24,
                slidesPerView: 4,
                roundLengths: true,
                lazy: true,
                navigation: {
                    nextEl: nextBtn,
                    prevEl: prevBtn,
                },
                breakpoints: {
                    1080: {
                        slidesPerView: 2.5,
                        spaceBetween: 24
                    },
                },
            });
        })
    }
}